export const FormMixin = {
  data() {
    return {
      beforeSubmit: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.beforeSubmit) {
      const answer = window.confirm(this.$t('alerts.leaveFormMessage'))
      if (answer) {
        this.resetForms()
        next()
      } else {
        next(false)
      }
    } else {
      next()
      this.resetForms()
    }
  },

  methods: {
    resetForms() {
      this.$refs.form.reset()
    }
  }
}
