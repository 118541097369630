<template>
  <base-form-layout
    :title="`${action} ${$tc('sale.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form v-if="!isLoading" ref="form">
      <v-row
        ><v-col cols="6">
          <h6 class="text-h6 mb-5">
            <v-icon left>mdi-text-box</v-icon> {{ $t('common.details') }}
          </h6>
          <v-divider class="mb-8"></v-divider>
          <SaleOrderEditForm :order="order" />
        </v-col>
        <v-col cols="6">
          <h6 class="text-h6 mb-5">
            <v-icon left>mdi-truck-delivery-outline</v-icon
            >{{ $t('load.deliveryTo') }}
          </h6>
          <v-divider class="mb-8"></v-divider>
          <CustomerSelector
            v-model="parties"
            :order="order"
            category="sale"
            :origin="origin"
            :destination="destination"
            @change="updateUserGroupId"
            @input="updateClient"
          />
        </v-col> </v-row
      ><v-row>
        <v-col cols="6">
          <h6 class="text-h6 mb-5">
            <v-icon left>mdi-clock</v-icon>{{ $tc('common.shipment', 1) }}
          </h6>
          <v-divider class="mb-8"></v-divider>
          <OriginLocation :order="order" :origin="origin" />
          <DestinationETA :destination="destination" />
          <IncotermSelector
            v-model="order.attributes.incoterms"
            :label="$t('selectors.incoterms')"
          />
        </v-col>
      </v-row>
    </v-form>
    <!-- <ItemProductIndexTableSelector
      v-if="!isLoading"
      title="Parts"
      :group-items="groupItems"
      :resource-id="orderId"
      @click:addItem="addItemToUpdate"
    /> -->
  </base-form-layout>
</template>

<script>
import CustomerSelector from './components/CustomerSelector'
import OriginLocation from './components/OriginLocation'
import SaleOrderEditForm from './components/SaleOrderEditForm'
import DestinationETA from '@/views/load/components/DestinationETA'
// import ItemProductIndexTableSelector from '@/views/item-product/components/ItemProductIndexTableSelector'
import IncotermSelector from './components/IncotermSelector'
import { FormMixin } from '@/mixins/form.mixin'
import { SALE_ORDER } from '@/models/sale.order'
import { LOAD } from '@/models/load'
import { mapActions } from 'vuex'
export default {
  components: {
    CustomerSelector,
    OriginLocation,
    SaleOrderEditForm,
    DestinationETA,
    IncotermSelector
    // ItemProductIndexTableSelector
  },
  mixins: [FormMixin],
  props: {
    orderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userGroupId: null,
      order: SALE_ORDER(),
      origin: LOAD('Origin'),
      destination: LOAD('Destination'),
      parties: [],
      clientId: null,
      groupItems: [],
      itemsToUpdate: [],
      isLoading: true
    }
  },
  computed: {
    action() {
      return this.$t(this.orderId ? 'actions.edit' : 'actions.create')
    }
  },

  async created() {
    const orderId = this.orderId
    if (orderId) {
      this.order = await this.fetchOrder(orderId)
      this.origin = await this.filterLoads(orderId, 'Origin')
      this.destination = await this.filterLoads(orderId, 'Destination')
      this.client = await this.filterParty(orderId, 'Client')
      this.groupItems = await this.filterItems(orderId)
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    updateUserGroupId(value) {
      this.userGroupId = value
    },
    updateClient(value) {
      this.clientId = value[0]?.attributes.userId
    },
    async filterItems(orderId) {
      return await this.$store.dispatch('itemEndProduct/filter', {
        orderId
      })
    },
    addItemToUpdate(item) {
      this.itemsToUpdate.push(item)
    },
    async fetchOrder(orderId) {
      return await this.$store.dispatch('saleOrder/fetch', orderId)
    },
    async filterLoads(orderIds, category) {
      const response = await this.$store.dispatch('load/filter', {
        orderIds,
        category
      })
      return response[0]
    },
    async filterParty(orderIds, name) {
      const response = await this.$store.dispatch('party/filter', {
        orderIds
      })
      return response.find(party => party.attributes.name === name) || {}
    },
    async updateItems(order) {
      for (let i = 0; i < this.itemsToUpdate.length; i++) {
        const item = this.itemsToUpdate[i]
        const { orderId } = item.attributes
        if (orderId) {
          item.attributes.orderId = ''
          item.attributes.clientId = ''
          item.attributes.itemGroupId = ''
          item.attributes.status = 'in_stock'
        } else {
          item.attributes.orderId = order.id
          item.attributes.clientId = this.clientId
          item.attributes.itemGroupId = ''
          item.attributes.status = 'out_stock'
        }
        await this.$store.dispatch('itemEndProduct/update', item)
      }
    },
    async onSubmit() {
      this.beforeSubmit = false
      const resources = [
        this.order,
        this.origin,
        this.destination,
        ...this.parties,
        this.merchandise
      ]
      this.orderId
        ? await this.updateOrder(resources)
        : await this.createOrder(resources)
    },
    async updateOrder(array) {
      const orderId = array[0].id
      array = array.filter(Boolean)
      const response = await this.submitOrder('update', array[0])
      if (!response) return
      await this.updateItems(response)
      for (let i = 1; i < array.length; i++) {
        const resource = array[i]
        const module = resource.type
        const action = resource.id ? 'update' : 'create'
        await this.$store.dispatch(`${module}/${action}`, resource)
      }
      this.$router.push({ name: 'sale-order-show', params: { orderId } })
    },

    async createOrder(array) {
      array = array.filter(Boolean)
      const response = await this.submitOrder('create', array[0])
      if (!response) return
      const orderId = response.id
      for (let i = 1; i < array.length; i++) {
        const resource = array[i]
        Object.assign(resource.attributes, { orderId })
        const module = resource.type
        await this.$store.dispatch(`${module}/create`, resource)
      }
      await this.updateItems(response)
      this.$router.push({ name: 'sale-order-show', params: { orderId } })
    },
    async submitOrder(action, order) {
      try {
        const response = await this.$store.dispatch(
          `saleOrder/${action}`,
          order
        )
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: 'Successfully updated' }
        })
        return response
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
