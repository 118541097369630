<template>
  <div></div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    origin: {
      type: Object,
      required: true
    }
  },
  async created() {
    const orderId = this.order.id
    if (!orderId) this.updateOrigin(this.currentUserAddress())
  },
  methods: {
    currentUserAddress() {
      const defaultAddress = {
        street: '',
        zipcode: '',
        city: '',
        state: '',
        country: '',
        value: '',
        latlng: {}
      }
      return (
        this.$store.state.auth.authUser.data.attributes.info.address ||
        defaultAddress
      )
    },
    updateOrigin(value) {
      if (!value) return null
      this.updateLocation(this.origin, value)
    },
    updateLocation(origin, address) {
      Object.assign(origin.attributes.location, address)
    }
  }
}
</script>
