import { ORGANIZATION_ID } from '@/common/config.js'

export const PARTY = (category, name) => ({
  type: 'party',
  attributes: {
    userId: '',
    category,
    orderId: '',
    organizationId: ORGANIZATION_ID(),
    name,
    customAttributes: { userGroupId: '' },
    contactName: null,
    companyName: null,
    permissions: {},
    details: null
  }
})
