<template>
  <base-selector
    v-bind="$attrs"
    :items="items"
    :item-text="
      item =>
        `${item.attributes.iccEce.toUpperCase()} [${item.attributes.code}] `
    "
    item-value="attributes.code"
    @input="$emit('input', $event)"
  />
</template>
<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      items: []
    }
  },
  async created() {
    const incoterms = await this.$store.dispatch(
      'terms/fetch',
      '/units/incoterms'
    )
    this.items = this._.orderBy(
      incoterms,
      [incoterm => incoterm.attributes.iccEce],
      ['asc']
    )
  }
}
</script>
