<template>
  <div>
    <base-field-input
      v-model="order.attributes.customAttributes.name"
      placeholder="1-20"
      :label="`${$tc('sale.name', 1)}#`"
    />
    <base-field-input
      v-model="order.attributes.customAttributes.purchaseOrders"
      :label="`Referenced ${$tc('purchase.purchaseOrder', 2)}#`"
      placeholder="02020/01"
    />

    <base-field-input
      v-model="order.attributes.customAttributes.invoiceNumber"
      placeholder="IN00001"
      :label="`${$tc('purchase.invoiceNumber', 2)}#`"
    />
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
