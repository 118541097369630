import { ORGANIZATION_ID } from '@/common/config.js'

export const LOAD = category => ({
  type: 'load',
  attributes: {
    orderId: null,
    organizationId: ORGANIZATION_ID(),
    location: {
      street: '',
      zipcode: '',
      city: '',
      state: '',
      country: '',
      value: '',
      latlng: {}
    },
    category,
    estimatedLoadDateFrom: null,
    estimatedLoadDateTo: null,
    estimatedUnloadDateFrom: null,
    estimatedUnloadDateTo: null,
    customAttributes: {}
  }
})
