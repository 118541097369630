import { ORGANIZATION_ID } from '@/common/config.js'
import store from '@/store/index.js'

export const SALE_ORDER = () => ({
  type: 'order',
  attributes: {
    userId: store.state.auth.authUser.data.id,
    organizationId: ORGANIZATION_ID(),
    clientOrganizationId: null,
    incoterms: '-',
    category: 'sea',
    subCategory: 'sale',
    referenceNumber: Date.now().toString(),
    details: '',
    status: 'pending',
    customAttributes: {
      name: '',
      purchaseOrders: '',
      invoiceNumber: '',
      blockchain: null
    }
  }
})
