<template>
  <v-autocomplete
    v-bind="$attrs"
    outlined
    :value="value"
    item-text="attributes.name"
    :items="userGroups"
    clearable
    @input="$emit('input', $event)"
    @click:clear="$emit('click:clear', $event)"
  >
    <template v-slot:append-item>
      <v-list-item-action>
        <v-btn
          small
          text
          color="primary"
          :to="{
            name: 'user-group-new',
            params: { category },
            query: { redirect: 'back' }
          }"
          ><v-icon left>mdi-plus</v-icon>{{ $t('actions.new') }}
          {{ $tc(`user.${category || 'name'}`, 1) }}</v-btn
        >
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [Object, Array],
      default: () => {}
    },
    category: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userGroups: []
    }
  },
  async created() {
    await this.filterUserGroups()
  },
  methods: {
    async filterUserGroups() {
      const usergroups = await this.$store.dispatch('userGroup/filter', {
        organizationId: ORGANIZATION_ID(),
        category: this.category
      })

      this.userGroups = this._.orderBy(
        usergroups,
        [group => group.attributes.name.toLowerCase()],
        ['asc']
      )
    }
  }
}
</script>
