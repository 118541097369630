<template>
  <base-date-picker
    :label="$t('load.etd')"
    :value="value"
    @input="updateValue"
  />
</template>

<script>
export default {
  props: {
    destination: {
      type: Object,
      required: true
    }
  },
  computed: {
    value() {
      return this.destination.attributes.estimatedUnloadDateTo
    }
  },
  methods: {
    updateValue(estimatedUnloadDateTo) {
      Object.assign(this.destination.attributes, { estimatedUnloadDateTo })
    }
  }
}
</script>
