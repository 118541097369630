<template>
  <div>
    <user-group-selector
      v-model="userGroup"
      :label="`${$t('selectors.select')} ${$tc('user.customer', 1)}`"
      category="customer"
      return-object
      @input="updateUserGroup"
      @click:clear="$emit('change', '')"
    />

    <base-user-select
      v-if="userGroup"
      v-model="selectedUsers"
      :label="`${$t('selectors.selectContactFrom')} ${$tc('user.customer', 1)}`"
      :multiple="true"
      :roles="roles"
      :group-id="userGroup.id"
      @input="updateUsers"
    />
    <!-- Selector just used as a placeholder -->
    <base-selector
      v-else
      :hint="$t('selectors.selectFirst', { user: $tc('user.customer', 1) })"
      persistent-hint
      disabled
    ></base-selector>
    <v-row>
      <v-col v-if="parties.length" cols="12" class="mb-5">
        <p class="green--text">
          {{ $t('selectors.greenContacts') }}
        </p>
        <template v-for="(party, i) in parties">
          <v-chip
            :key="i"
            label
            class="my-2 mr-2 text-capitalize"
            close
            :color="party.id ? 'green lighten-3' : 'amber lighten-3'"
            text-color="white"
            close-icon="mdi-delete"
            @click:close="removeParty(i)"
          >
            <v-avatar left>
              <v-icon>mdi-account-outline</v-icon>
            </v-avatar>
            {{ party.attributes.contactName || party.attributes.companyName }}
            [{{
              $tc(
                `user.${party.attributes.customAttributes.role || 'customer'}`,
                1
              )
            }}]
          </v-chip>
        </template>
      </v-col>
      <v-col v-if="userGroup" cols="12" class="mb-5">
        <base-address-card
          class="py-3 green lighten-5"
          :name="userGroup.attributes.name"
          :address="userGroup.attributes.customAttributes.address"
        >
        </base-address-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserGroupSelector from '@/views/user-group/components/UserGroupSelector'
import { PARTY } from '@/models/party'
import { mapGetters } from 'vuex'
export default {
  components: {
    UserGroupSelector
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    // origin: {
    //   type: Object,
    //   required: true
    // },
    destination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userGroup: null,
      roles: 'customer',
      selectedUsers: [],
      parties: []
    }
  },
  computed: {
    ...mapGetters({ organizationId: 'auth/organizationId' })
  },

  async created() {
    const orderIds = this.order.id
    if (orderIds) {
      this.parties = await this.filterParties(orderIds)
      const groupId = this.parties[0]?.attributes?.customAttributes.userGroupId
      if (groupId) {
        this.userGroup = await this.$store.dispatch('userGroup/fetch', groupId)
        this.emitUserGroupId()
      }
      const userIds = this.parties
        .map(party => party?.attributes?.userId)
        .join(',')
      if (userIds)
        this.selectedUsers = await this.$store.dispatch('user/filter', {
          userIds
        })
      this.$emit('input', this.parties)
    }
  },
  methods: {
    async filterParties(orderIds) {
      if (!orderIds) return
      return await this.$store.dispatch('party/filter', {
        orderIds
      })
    },
    async updateUsers(value) {
      if (!value.length) return this.$emit('input', this.parties)
      // Add main organization_id from first user in the list.
      const organizationId = value[0].attributes.organizationId
      Object.assign(this.order.attributes, {
        clientOrganizationId:
          this.organizationId !== organizationId ? organizationId : null
      })

      if (this.order.id) this.parties = await this.filterParties(this.order.id)

      this.selectedUsers.forEach(user => {
        const userIsParty = this.checkIfUserIsParty(user)
        if (userIsParty) return
        const party = PARTY('Destination', 'Client')
        const attributes = {
          userId: user.id,
          orderId: this.order.id,
          companyName: this.userGroup.attributes.name,
          contactName: user.attributes.info.name,
          customAttributes: {
            role: user.attributes.roles[1],
            userGroupId: this.userGroup.id
          }
        }
        Object.assign(party.attributes, attributes)
        this.parties.push(party)
      })
      this.$emit('input', this.parties)
    },

    checkIfUserIsParty(user) {
      if (!this.parties.length) return
      const userIds = this.parties.map(party => party.attributes.userId)
      return userIds.includes(user.id)
    },
    updateUserGroup(value) {
      if (!value) return

      const load = this.destination
      Object.assign(
        load.attributes.location,
        this.userGroup.attributes.customAttributes.address
      )
      this.emitUserGroupId()
    },
    removeParty(index) {
      if (this.order.id)
        this.$store.dispatch('party/delete', this.parties[index])
      this.removeSelectedUser(index)
      this.parties.splice(index, 1)

      this.$emit('input', this.parties)
    },
    removeSelectedUser(index) {
      const userIndex = this.selectedUsers
        .map(user => user.id)
        .indexOf(this.parties[index].attributes.userId)
      this.selectedUsers.splice(userIndex, 1)
    },
    emitUserGroupId() {
      this.$emit('change', this.userGroup.id)
    }
  }
}
</script>
